<template>
  <Modal v-if="modal" class="TransporterTransferHistoryModal__Modal" @close="close">
    <template #header>
      <div class="TransporterTransferHistoryModal__Modal__Header">
        <span>Histórico no redespacho e transferência <b>{{ data.id }}</b> </span>
        <span><b>Transportadora destino - {{ data.destiny_transporter_name }}</b> </span>
      </div>
    </template>
    <template #body>
      <div class="TransporterTransferHistoryModal__Modal__Body">
        <DataTable
          :header="modalTransferHistory.data.headers"
          :table="modalTransferHistory.data.content"
          @track="trackOrder"
        />
        <Pagination v-if="modalTransferHistory.data.content.length" @page="search()" @paginate="search()" />
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { DataTable, Modal, Pagination } from '@/components/molecules'
export default {
  name: 'TransporterTransferHistoryModal',
  components: {
    DataTable,
    Modal,
    Pagination
  },
  data() {
    return {
      modal: false,
      data: null
    }
  },
  computed: {
    ...mapState({
      modalTransferHistory: state => state.transporters.modalTransferHistory,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),

  },
  watch: {
    data(val) {
      if (val) this.search(val)
    },
  },
  methods: {
    ...mapActions({
      fetchModalTransferHistory: 'transporters/fetchModalTransferHistory',
      setPagination: 'pagination/setPagination',
    }),

    search() {
      const params = {
        id: this.data.id,
        paginate: this.paginate,
        page: this.page
      }

      this.fetchModalTransferHistory(params).then(() => {
        this.setPagination(this.modalTransferHistory)
      })
    },

    trackOrder(data){
      if (!data.tracking_code) return
      
      const url = `${window.location.origin}/tracking/${data.tracking_code}`
      window.open(url, '_blank')
    }, 

    //Handlers
    open(data) {
      this.modal = true
      this.data = data
      this.$emit('open')
    },

    close() {
      this.modal = false
      this.data = null
      this.$emit('close')
    },
  }
}
</script>
<style lang="scss" scoped>
.TransporterTransferHistoryModal {
  &__Modal {

    /deep/ .Modal__container {
      width: auto;
      height: auto;
      max-height: 90vh;
      min-width: 800px;
    }

    &__Header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      span {
        font-weight: $font-weight-bold;
        padding-left: 10px;
      }
    }

    &__Body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding: 1.5rem 1.5rem;
    }

  }
}
</style>
