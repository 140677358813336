<template>
  <main class="TransporterTransferHistory">
    <TheSubHeader 
      icon="dashboard-package-request" 
      title="Histórico de redespacho e transferência"
      type="filter"
      :actions="['back']"
      organizer-gap="1rem"
      organizer-columns="248px 1fr 1fr 120px"
    >
      <template #filter>
        <!-- Period -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="Intervalo entre datas"
          :not-after="todayParsed"
        />
        <!-- User -->
        <BaseTreeSelect
          v-model="filters.user"
          name="Usuário"
          label="Usuário"
          placeholder="Selecione"
          multiple
          suggestions="searchUsers"
          @input="resetSearch()"
        />
        <!-- Transporters -->
        <BaseTreeSelect
          v-model="filters.transporter"
          name="Transportadora"
          label="Transportadora"
          placeholder="Selecione"
          multiple
          :select-options="transporters"
          @input="resetSearch()"
        />
        <!-- Filter -->
        <BaseButton
          label="Filtrar"
          theme="icon-left"
          color="primary-base"
          filled
          icon="filter"
          text-color="white"
          @click="resetSearch()"          
        />
      </template>
    </TheSubHeader>
    <div class="TransporterTransferHistory__content">
      <div v-if="!firstSearch" class="TransporterTransferHistory__table">
        <DataTable
          is-sticky
          :header="transferHistoryTable.data.headers"
          :table="transferHistoryTable.data.content"
          @see="openModalTransferHistory"
        />
        <Pagination v-if="transferHistoryTable.data.content.length" @page="search()" @paginate="search()" />
      </div>
      <div v-else class="TransporterTransferHistory__firstSearch">
        <p>Utilize o filtro para exibir os pedidos.</p>
      </div>
    </div>

    <!-- Transporter Transfer History Modal -->
    <TransporterTransferHistoryModal ref="TransporterTransferHistoryModal" @close="handleCloseModal" />
  </main>
</template>

<script>
import { BaseButton, BaseDatePicker, BaseTreeSelect } from '@/components/atoms'
import { TheSubHeader } from '@/components/organisms'
import { DataTable, Pagination } from '@/components/molecules'
import { mapActions, mapState } from 'vuex'

import TransporterTransferHistoryModal from '@/views/transporters/transfer/TransporterTransferHistoryModal'

export default {
  name: 'TransporterTransferHistory',
  components: {
    BaseButton,
    BaseDatePicker,
    BaseTreeSelect,
    DataTable,
    Pagination,
    TheSubHeader,
    TransporterTransferHistoryModal,
  },
  data() {
    return {
      filters: {
        dateRange: {
          init: null,
          end: null,
        },
        user: null,
        transporter: null,
      },
      firstSearch: true,
    }
  },
  computed:{
    ...mapState({
      transporters: state => state.transporters.transporters,
      transferHistoryTable: state => state.transporters.transferHistoryTable,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),

    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

    todayParsed() {
      const today = new Date()
      return Date.parse(today)
    },
  },
  
  mounted() {
    this.fetchTransporters()
  },

  methods:{
    ...mapActions({
      fetchTransporters: 'transporters/fetchTransporters',
      fetchTransferHistoryTable: 'transporters/fetchTransferHistoryTable',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination'
    }),

    search() {
      const params = {
        obj: {
          date_start: this.filters.dateRange.init,
          date_end: this.filters.dateRange.end,
          user_id: this.filters.user,
          destiny_transporter_id: this.filters.transporter,
        },
        paginate: this.paginate,
        page: this.page
      }

      this.fetchTransferHistoryTable(params)
        .then(() => {
          this.firstSearch = false
          this.setPagination(this.transferHistoryTable)
        })
    },

    resetSearch() {
      this.clearPagination().then(() => this.search())
    },

    openModalTransferHistory(data) {
      this.$refs.TransporterTransferHistoryModal?.open(data)
    },

    handleCloseModal() {
      this.setPagination(this.transferHistoryTable)
    },
  }

}
</script>

<style lang="scss" scoped>
.TransporterTransferHistory {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }

  &__table {
    grid-area: main;
    margin-bottom: 50px;
  }

  &__firstSearch {
    grid-area: main;
    margin-bottom: 50px;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      margin: 0;
      padding: 0;
      text-align: center;
      background: $gradient-primary;
    }
  }

  .Organizer__filter{
    div{
      @media (max-width: $viewport-sm) {
        width: 100%;
        section:last-child{
          margin-top: 18px;
        }
      }
      &:last-child{
        .BaseButton{
          margin-top: 18px;
        }
      }
    }
  }
}
</style>